<template>
  <div>
    <h3 class="mb-5 text-secondary">{{ $t("page.dashboard.title") }}</h3>
    <div class="row no-gutters ml-n3 mb-5">
      <div
        v-for="(metrics, index) in metricsList"
        :key="index"
        class="col-12 col-sm-3 pl-3 mb-3 mb-sm-0"
      >
        <metrics-card
          :total="metrics.total"
          :description="metrics.description"
          :bgIcon="metrics.icon"
          :bgColor="metrics.bgColor"
          :callbackFunc="metrics.callbackFunc"
          :paramsFunc="metrics.paramsFunc"
        />
      </div>
    </div>
    <h4 class="font-weight-normal">
      {{ $t("page.dashboard.yourCondominiums") }}
    </h4>
    <b-alert
      :show="isCondominiumListEmpty"
      variant="warning"
      class="mb-0 font-weight-bold"
    >
      {{ $t("page.dashboard.noCondominiumWarningMsg") }}
    </b-alert>
    <g-map :centerCoordinates="mapCenterCoordinates" :markers="mapMarkers" />
  </div>
</template>

<script>
import Vue from "vue";
import authMixin from "./../mixins/authMixin";
import { EventBus } from "./../eventBus.js";

import GMap from "./../components/Google/GoogleMaps";
import MetricsCard from "./../components/Base/MetricsCard";

import CondominiumMapInfo from "./../components/Condominium/CondominiumMapInfo";
const InfoWindow = Vue.extend(CondominiumMapInfo);
export default {
  components: {
    GMap,
    MetricsCard
  },
  mixins: [authMixin],
  data() {
    return {
      freeCondominiumMarker: require("@/assets/marker-freeCondominium.png"),
      proCondominiumMarker: require("@/assets/marker-proCondominium.png")
    };
  },
  async mounted() {
    EventBus.$on("eventBusReloadDashboard", this.reloadData);
  },
  computed: {
    condominiums() {
      return this.$store.getters["condominium/getActiveCondominiums"];
    },
    allCondominiums() {
      return this.$store.getters["condominium/getCondominiums"];
    },
    maintainers() {
      return this.$store.getters["maintainer/getMaintainers"].filter(
        m => !m.userId || (m.userId && m.userId.status === "active")
      );
    },
    selectedAdmin() {
      return this.$store.getters["filter/getAdministratorSelected"];
    },
    consumers() {
      return this.$store.getters["consumer/getConsumerUsers"];
    },
    mapMarkers() {
      return this.allCondominiums.map(condominium => {
        return {
          _id: condominium._id,
          position: { lat: condominium.latitude, lng: condominium.longitude },
          content: this.condominiumDOMElement(condominium),
          icon:
            condominium.status === "free"
              ? this.freeCondominiumMarker
              : this.proCondominiumMarker
        };
      });
    },
    mapCenterCoordinates() {
      return this.mapMarkers.length > 0 ? this.mapMarkers[0].position : null;
    },
    isCondominiumListEmpty() {
      return this.condominiums.length === 0;
    },
    metricsList() {
      // const pendingIssues = this.condominiums.reduce(
      //   (oldValue, condominium) => {
      //     return oldValue + condominium.pendingIssues;
      //   },
      //   0
      // );
      // const condominiumsNum = this.allCondominiums.filter(
      //   con => con.status === "free" || con.status === "pro"
      // ).length;
      // var maintainersNum = 0;
      // var peopleNum = 0;
      // if (this.$_authUserIsDomi) {
      //   peopleNum = this.consumers.filter(
      //     consumer => consumer?.status === "active"
      //   ).length;
      //   const maintainers = this.maintainers.filter(
      //     m => m.administrators.length
      //   );
      //   if (this.selectedAdmin) {
      //     const adminMaintainers = maintainers.filter(am =>
      //       am.administrators.includes(this.selectedAdmin._id)
      //     );
      //     peopleNum = this.condominiums.reduce((oldValue, condominium) => {
      //       return oldValue + condominium.peopleNum;
      //     }, 0);
      //     maintainersNum = adminMaintainers.length;
      //   } else {
      //     maintainersNum = maintainers.length;
      //   }
      // } else {
      //   peopleNum = this.condominiums.reduce((oldValue, condominium) => {
      //     return oldValue + condominium.peopleNum;
      //   }, 0);
      //   const generalInfo = this.$_authUser.generalInfo;
      //   maintainersNum = generalInfo?.maintainersNum;
      // }

      const metrics = this.$store.getters["condominium/getMetrics"];
      return [
        {
          total: metrics.pendingIssuesNum,
          description: "metrics.pendingIssues",
          icon: "tools",
          bgColor: "gray-chateau",
          callbackFunc: this.goToPage,
          paramsFunc: {
            routeName: "Issues"
          }
        },
        {
          total: metrics.condominiumsNum,
          description: "metrics.condominiums",
          icon: "building",
          bgColor: "cyan",
          callbackFunc: this.goToPage,
          paramsFunc: {
            routeName: "Condominiums"
          }
        },
        {
          total: metrics.activeUsersNum,
          description: "metrics.subjects",
          icon: "people-fill",
          bgColor: "cyan-400"
        },
        {
          total: metrics.maintainersNum,
          description: "metrics.maintainers",
          icon: "bricks",
          bgColor: "cyan-500",
          callbackFunc: this.goToPage,
          paramsFunc: {
            routeName: "Maintainers"
          }
        }
      ];
    }
  },
  methods: {
    async reloadData() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("condominium/retrieveCondominiums");
        await this.$store.dispatch("condominium/retrieveMetrics");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    condominiumDOMElement(condominium) {
      return new InfoWindow({
        propsData: {
          condominium: condominium,
          router: this.$router
        }
      }).$mount().$el;
    },
    goToPage({ routeName }) {
      this.$router.push({ name: routeName });
    }
  },
  beforeDestroy() {
    EventBus.$off("eventBusReloadDashboard");
  }
};
</script>

<style lang="scss" scoped>
.alert {
  border-left: 10px solid var(--yellow);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
