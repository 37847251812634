<template>
  <div>
    <h5
      class="font-weight-normal clickable link text-primary"
      @click="goToCondominiumDetails"
    >
      {{ condominium.name }}
    </h5>
    <div>{{ condominium.address }} {{ condominium.streetNumber }}</div>
    <div>{{ condominium.zip }}, {{ condominium.city }}</div>
    <div>{{ condominium.country }} ({{ condominium.province }})</div>
  </div>
</template>

<script>
export default {
  props: {
    condominium: {
      type: Object,
      required: true
    },
    router: {
      type: Object,
      required: true
    }
  },
  methods: {
    goToCondominiumDetails() {
      this.router.push({
        name: "CondominiumManagement",
        params: { condominiumId: this.condominium._id }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.link:hover {
  text-decoration: underline;
}
</style>
