<template>
  <div>
    <div id="map" ref="map"></div>
  </div>
</template>

<script>
import gmapsInit from "./../../plugins/gmaps.js";
export default {
  props: {
    centerCoordinates: {
      type: [Object, null],
      default: null
    },
    markers: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    markers: {
      handler: function() {
        this.removeGoogleMapMarkers();
        this.setGoogleMapMarkers();
      },
      immediate: false
    }
  },
  data() {
    return {
      google: null,
      map: null,
      googleMarkers: [],
      initZoom: 6,
      focusZoom: 8
    };
  },
  async mounted() {
    try {
      const DEFAULT_MAP_CENTER = { lat: 45.467088, lng: 9.190325 };
      this.google = await gmapsInit();
      this.map = new this.google.maps.Map(this.$refs.map, {
        center: this.centerCoordinates || DEFAULT_MAP_CENTER,
        zoom: this.initZoom
      });
      this.initGoogleMapMarkers();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    initGoogleMapMarkers() {
      this.markers.forEach(item => {
        this.addMarker(item);
      });
    },
    setGoogleMapMarkers() {
      this.markers.forEach(item => {
        const index = this.googleMarkers.findIndex(googleMarker => {
          return googleMarker.metadata.id === item._id;
        });
        if (index < 0) {
          this.addMarker(item);
        }
      });
    },
    removeGoogleMapMarkers() {
      for (var i = this.googleMarkers.length - 1; i >= 0; i--) {
        const index = this.markers.findIndex(item => {
          return item._id === this.googleMarkers[i].metadata.id;
        });
        if (index < 0) {
          this.googleMarkers[i].setMap(null);
          window.google.maps.event.clearInstanceListeners(
            this.googleMarkers[i]
          );
          this.googleMarkers.splice(i, 1);
        }
      }
    },
    removeAllGoogleMapMarkers() {
      this.googleMarkers.forEach(marker => {
        marker.setMap(null);
        window.google.maps.event.clearInstanceListeners(marker);
      });
    },
    addMarker(info) {
      const marker = this.setMarkerInfo(info);
      marker.metadata = { id: info._id };
      this.googleMarkers.push(marker);
    },
    setMarkerInfo(markerInfo) {
      const marker = new this.google.maps.Marker({
        position: markerInfo?.position,
        map: this.map,
        icon: markerInfo?.icon
      });

      if (markerInfo?.content) {
        this.attachMarkerInfoWindow(marker, markerInfo.content);
      } else if (markerInfo?.callbackFunc) {
        marker.addListener("click", () => {
          markerInfo.callbackFunc(markerInfo._id);
        });
      }

      return marker;
    },
    attachMarkerInfoWindow(marker, content) {
      const infowindow = new this.google.maps.InfoWindow({
        content: content
      });

      marker.addListener("click", () => {
        if (this.isInfoWindowOpen(infowindow)) {
          infowindow.close();
        } else {
          infowindow.open(marker.get("map"), marker);
          if (this.map.getZoom() < this.focusZoom) {
            this.map.setZoom(this.focusZoom);
            this.map.setCenter(marker.getPosition());
          }
        }
      });
    },
    isInfoWindowOpen(infoWindow) {
      var map = infoWindow.getMap();
      return map !== null && typeof map !== "undefined";
    }
  },
  beforeDestroy() {
    this.removeAllGoogleMapMarkers();
  }
};
</script>

<style lang="scss" scoped>
#map {
  height: 600px;
  width: 100%;
  background-color: grey;
}
</style>
