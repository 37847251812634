<template>
  <b-card
    no-body
    class="border-0 p-3"
    :class="[
      bgColor ? `bg-${bgColor}` : 'bg-secondary',
      { clickable: isClickable }
    ]"
    @click="isClickable && onClick()"
  >
    <div class="content">
      <div class="text-right">
        <h2 class="font-weight-normal">{{ total }}</h2>
        <div>{{ $t(description) }}</div>
      </div>
    </div>
    <b-icon class="bg-icon" :icon="bgIcon" />
  </b-card>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    description: {
      type: String,
      default: ""
    },
    bgIcon: {
      type: String,
      default: ""
    },
    bgColor: {
      type: String,
      default: "secondary"
    },
    callbackFunc: {
      type: [Function, null],
      default: null
    },
    paramsFunc: {
      type: [Object, null],
      default: null
    }
  },
  computed: {
    isClickable() {
      return this.callbackFunc !== null;
    }
  },
  methods: {
    onClick() {
      this.callbackFunc(this.paramsFunc);
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
  min-height: 140px;
  overflow: hidden;
  color: white;
  position: relative;
  .bg-icon {
    position: absolute;
    bottom: -20px;
    left: -20px;
    width: 100px;
    height: 100px;
    opacity: 0.3;
  }
  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
